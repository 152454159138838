import { Button, Flex, Subhead, Text } from "../components/ui"
import React, { useState } from "react"
import { PageContainer } from "../basic/PageContainer"
import { theme } from "../theme.css"

const page = {
  title: "Platzbelegungsplan",
  description: "Platzbelegungsplan des FC Ottenhöfen",
}

export default function teams() {
  React.useEffect(() => {
    const isAccepted = !!window.localStorage.getItem("google-tracking-accepted")
    if (isAccepted) {
      setAccepted(true)
    }
  }, [])

  const [accepted, setAccepted] = useState(false)

  return (
    <PageContainer page={page}>
      {!accepted && <Subhead>Datenschutz</Subhead>}
      {!accepted && (
        <Text style={{ color: theme.colors.long_text }} as="p">
          Diese Webseite verwendet Google Calendar um einen Kalendar
          einzubinden. Bitte beachten Sie dass hierbei Ihre persönlichen Daten
          erfasst und gesammelt werden können. Um den Google Calendar Kalendar
          zu sehen, stimmen Sie bitte zu, dass diese vom Google-Server geladen
          wird. Weitere Informationen finden sie <a href="/datenschutz">HIER</a>
          .
        </Text>
      )}
      {!accepted && (
        <Flex alignItems="center">
          <Button
            onClick={() => {
              window.localStorage.setItem("google-tracking-accepted", "X")
              setAccepted(true)
            }}
          >
            Akzeptieren
          </Button>
        </Flex>
      )}
      {accepted && (
        <iframe
          style={{ borderWidth: 0 }}
          src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FBerlin&amp;showPrint=0&amp;showTitle=1&amp;showTz=0&amp;showTabs=1&amp;showCalendars=0&amp;src=MTUwMWIwYzMxNThmNTM4NjY3MzYyNjA1YmMyYzRiNGM1MzI2NzBkNWI2YzRlZWJkZTNjODUyODRhZWI3NjBlOUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23AD1457"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
        />
      )}
    </PageContainer>
  )
}
